<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>{{ title }} Transaction Heading</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  v-model="service.title"
                  :error="$v.service.title.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Name <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.service.title.$error">Name is required</span>
              <span class="text-danger" v-if="errors.title" >* {{ errors.title[0] }}</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-text-field
                  v-model="service.price"
                  :error="$v.service.price.$error || !$v.service.price.numeric"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Price <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="$v.service.price.$error">Price is required</span>
              <span class="text-danger" v-if="errors.price" >* {{ errors.price[0] }}</span>
            </v-col>

            <v-col cols="12" md="12">
              <v-select
                  v-model="service.type"
                  :items="status"
                  item-text="title"
                  item-value="value"
                  :error="$v.service.type.$error"
                  outlined
                  dense
              >
                <template v-slot:label>
                  Type <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.service.type.$error">Type is required</span>
              <span class="text-danger" v-if="errors.type" >* {{ errors.type[0] }}</span>
            </v-col>

            <v-col cols="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                :config="editorConfig"
                v-model="service.description"
              >
              </ckeditor>
              <span class="text-danger" v-if="$v.service.description.$error">Description Field is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

            <v-col cols="12" md="6">
              Status
              <v-switch
                  v-model="service.is_active"
                  :label="service.is_active ? 'Active' : 'Inactive'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="closeDialog">
          Cancel
        </v-btn>

        <v-btn
            v-if="checkIsAccessible('service', 'create') || checkIsAccessible('service', 'edit')"
            class="btn btn-primary"
            dark
            medium
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import ServiceService from "@/services/service/ServiceService";

const service = new ServiceService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      edit: false,
      dialog: false,
      loading: false,
      errors: [],
      service: {
        title: '',
        position: '',
        price: '',
        type: 'normal_fee',
        description: '',
        is_active: true,
      },
      status: [
        {title: 'Normal fee', value: 'normal_fee'},
        {title: 'Application fee', value: 'application_fee'},
        {title: 'Visa fee', value: 'visa_fee'},
        {title: 'Subscription', value: 'subscription'},
      ],
      editorConfig: {
        versionCheck: false,
        toolbar: [
            [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ]
      }
    }
  },
  validations: {
    service: {
      title: { required },
      type: { required },
      price: { required, numeric },
      description: { required },
      is_active: { required }
    }
  },
  methods: {
    closeDialog(){
      this.dialog = false;
      this.$v.$reset();
    },
    createService(){
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editService(service){
      this.title = "Edit"
      this.dialog = true;
      this.edit = true;
      this.service = service;
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      service
          .create(this.service)
          .then(response =>{
            this.loading = false;
            this.$snotify.success("Service created successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      service
          .update(this.service.id,this.service)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Service updated successfully");
            this.resetForm();
            this.closeDialog();
            this.$emit('refresh');
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    resetForm(){
      this.$v.$reset();
      this.service = {
        title: '',
        position: '',
        price: '',
        type: 'normal_fee',
        description: '',
        is_active: true,
      }
    }
  }
}
</script>

<style scoped>

</style>
