<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Transaction Headings</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Transaction Headings
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      v-if="checkIsAccessible('service', 'create')"
                      @click="createService()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Transaction Headings
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <v-text-field
                        label="Name"
                        v-model="search.title"
                        v-on:keyup.enter="searchService"
                        @input="search.title = $event !== null ? $event : ''"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                        label="Min Price"
                        v-model="search.min_price"
                        v-on:keyup.enter="searchService"
                        @input="search.price = $event !== null ? $event : ''"
                        type="number"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                        label="Max Price"
                        v-model="search.max_price"
                        v-on:keyup.enter="searchService"
                        @input="search.price = $event !== null ? $event : ''"
                        type="number"
                        clearable
                        outlined
                        dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-select
                        label="Status"
                        v-model="search.is_active"
                        v-on:keyup.enter="searchService"
                        @input="search.is_active = $event !== null ? $event : ''"
                        :items="status"
                        item-text="name"
                        item-value="value"
                        clearable
                        outlined
                        dense
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="2">
                    <v-btn
                        @click.prevent="searchService"
                        v-on:keyup.enter="searchService"
                        class="btn btn-primary"
                        :loading="loading"
                    >
                      <v-icon small  outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </v-row>
            </div>

            <!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>
                  <th><strong>Price</strong></th>
                  <th><strong>Type</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>

                <template>
                  <draggable class="w-100" v-model="services" @change="sort" @start="drag=true" tag="tbody" @end="drag=false">
                    <tr v-for="(service, index) in services" :key="index">
                      <td>
                        <a @click="editService(service)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          <i class="fa fa-sort"></i> {{ service.title }}
                        </a>
                      </td>
                      <td>
                        {{ service.price }}
                      </td>
                      <td>
                        <span class="font-weight-bolder text-dark-65">
                          {{ service.type_text }}
                        </span>
                      </td>

                      <td>
                        <span class="badge badge-success text-lg`"
                              v-bind:class="{ 'badge-success': service.is_active, 'badge-danger': !service.is_active }"
                        >{{ service.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>

                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('service', 'edit')">
                                <a class="navi-link" @click="editService(service)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('service', 'delete')">
                                <a class="navi-link" @click.prevent="deleteService(service.id)">
                                  <span class="navi-icon">
                                      <v-icon color="red darken-2">fas fa-trash-alt</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="services.length == 0">
                      <td class="text-center font-weight-bold px-3" colspan="6">No Data Found</td>
                    </tr>
                  </draggable>
                </template>

              </table>

            </div>
            <!-- table ends -->
            <v-overlay :value="isSorting">
              <v-progress-circular
                  indeterminate
                  color="dark"
              ></v-progress-circular>
            </v-overlay>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllServices"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "@/view/pages/view/service/CreateAndUpdate";
import draggable from "vuedraggable";
import ServiceService from "@/services/service/ServiceService";

const service = new ServiceService();

export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return{
      services:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      isSorting: false,
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
      search:{
        title:'',
        category_ids: '',
        is_active:'',
        min_price:'',
        max_price:'',
      }
    }
  },
  mounted() {
    this.getAllServices();
  },
  methods: {
    createService(){
      this.$refs['create-and-update'].createService();
    },
    editService(service){
      this.$refs['create-and-update'].editService(service);
    },
    getAllServices(){
      this.loading = true;
      service
      .paginate(this.search, this.page)
      .then(response => {
        this.services = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    sort(){
      this.isSorting = true;
      service
          .sort(this.services)
          .then(response => {
            this.isSorting = false;
            this.$snotify.success('Service Sorted !!');
            this.getAllServices()
          })
          .catch(err => {
            this.isSorting = false;
            this.$snotify.error("Oops something went wrong");
          });
    },

    searchService(){
      this.getAllServices();
    },
    deleteService(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            service
                .delete(id)
                .then((response) => {
                  this.getAllServices()
                  this.$snotify.success("Service Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }
  }
}
</script>